export const SET_TYPES = 'SET_TYPES';

export function setTypes(types) {
	return {
		type: SET_TYPES,
		payload: {
			types,
		},
	};
}

