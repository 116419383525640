import * as faIcons from '@fortawesome/free-solid-svg-icons';

export const icons = [
	faIcons.faAdjust,
	faIcons.faBars,
	faIcons.faCheck,
	faIcons.faChevronUp,
	faIcons.faChevronDown,
	faIcons.faCloudUploadAlt,
	faIcons.faCog,
	faIcons.faColumns,
	faIcons.faCopy,
	faIcons.faEdit,
	faIcons.faEnvelope,
	faIcons.faFastBackward,
	faIcons.faFastForward,
	faIcons.faFileCsv,
	faIcons.faFileDownload,
	faIcons.faFileInvoice,
	faIcons.faHandHoldingUsd,
	faIcons.faHistory,
	faIcons.faKey,
	faIcons.faLock,
	faIcons.faMailBulk,
	faIcons.faNetworkWired,
	faIcons.faPlus,
	faIcons.faStepBackward,
	faIcons.faStepForward,
	faIcons.faSync,
	faIcons.faTable,
	faIcons.faThList,
	faIcons.faTimes,
	faIcons.faToggleOff,
	faIcons.faToggleOn,
	faIcons.faTools,
	faIcons.faUserMinus,
	faIcons.faUsers,
	faIcons.faUsersCog,
];
