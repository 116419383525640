export function sortStrings(i1, i2) {
	const label1 = i1.toLowerCase();
	const label2 = i2.toLowerCase();
	if (label1 < label2) return -1;
	if (label1 > label2) return 1;
	return 0;
}

/**
 * @param array Array
 * @returns Array
 */
export function isort(array) {
	return array.slice().sort(sortStrings);
}

export function isortName(array) {
	return array.slice().sort((a, b) => {
		const ia = a.name.toLowerCase();
		const ib = b.name.toLowerCase();

		if (ia < ib) return -1;
		if (ia > ib) return 1;
		return 0;
	});
}
