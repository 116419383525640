import {
	CLEAR_CAMPAIGN,
	CLEAR_SELECTED_MAILING, CLEAR_SELECTIONS,
	SET_CAMPAIGN,
	SET_CAMPAIGNS,
	SET_SELECTED_MAILING, SET_SELECTIONS,
} from '../actions/campaigns';

const initialState = {
	campaigns: null,
	selectedCampaign: null,
	selectedMailing: null,
	selections: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CAMPAIGNS:
			return Object.assign({}, state, { campaigns: action.payload.campaigns });

		case SET_CAMPAIGN:
			return Object.assign({}, state, { selectedCampaign: action.payload.campaign });

		case CLEAR_CAMPAIGN:
			return Object.assign({}, state, { selectedCampaign: initialState.selectedCampaign });

		case SET_SELECTED_MAILING:
			return Object.assign({}, state, { selectedMailing: action.payload.mailing });

		case CLEAR_SELECTED_MAILING:
			return Object.assign({}, state, { selectedMailing: initialState.selectedMailing });

		case SET_SELECTIONS:
			return Object.assign({}, state, { selections: action.payload.selections });

		case CLEAR_SELECTIONS:
			return Object.assign({}, state, { selections: initialState.selections });

		default:
			return state;
	}
};

export default reducer;
