import { SET_ACCOUNT } from '../actions/auth';

const initialState = {
	account: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ACCOUNT:
			return Object.assign({}, state, { account: action.payload.account });

		default:
			return state;
	}
};

export default reducer;
