const initialState = {
	menu: 'closed',
};

const BASE_KEY = 'SET_MISC_';

const reducer = (state = initialState, action) => {
	const {type} = action;

	if (type.startsWith(BASE_KEY)) {
		const suffix = type.substring(BASE_KEY.length).toLowerCase();

		return {...state, [suffix]: action.payload[suffix] };
	}

	return state;
};

export default reducer;
