import {SET_PAGINATOR_PAGE} from '../actions/paginator';

const initialState = {
	pages: {},
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PAGINATOR_PAGE: {
			const {name, pageNum} = action.payload;
			const pages = Object.assign({}, state.pages, {
				[name]: pageNum,
			});

			return Object.assign({}, state, { pages });
		}

		default:
			return state;
	}

};

export default reducer;
