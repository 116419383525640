import React, { Fragment } from 'react';
import {Button, Form, FormGroup, Input, Label, FormText} from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Select from 'react-select';

import {withFormUtils} from '../../hoc/with-form-utils';
import {MailingState} from '../../enum/MailingState';
import {MailingStateText} from '../../enum/MailingStateText';
import {PropTypes} from 'prop-types';

function ExternalIdForm({ externalIds, onInputChange, provider }) {
	let pattern;
	if(provider === 'webpower') {
		pattern = '\\d:\\d';
	}
	const inputs = externalIds.map((id, i) => <Input key={i} type="text" name="externalId" className="mb-1" value={id} onChange={e => {
		const value = externalIds.slice();
		value[i] = e.target.value;
		onInputChange({ target: { name: 'externalId', value }});
	}} pattern={pattern} />);
	if(provider === 'webpower') {
		return <FormGroup>
			<Label>Vul je Webpower Campaign en Mailing ID in gescheiden door een <code>:</code></Label>
			{inputs}
			<FormText>
				Voorbeeld ID: <strong>1:63</strong>
			</FormText>
		</FormGroup>;
	}
	return <FormGroup>
		<Label>Vul je Tripolis Mailing ID in</Label>
		{inputs}
		<FormText>
			Voorbeeld Mailing ID: <strong>7y79orw7EPMvWO1pUi0iug</strong><br />
			Die kan je halen uit je jobSummaryReport:<br />
			https://td38.tripolis.com/dialogue/reports/publishing/emails/jobSummaryReport.html?id=<strong>7y79orw7EPMvWO1pUi0iug</strong>&amp;list=all
		</FormText>
	</FormGroup>;
}

function MailProviderForm({ externalIds, onInputChange, provider }) {
	return <Fragment>
		<Label>Selecteer een e-mail provider</Label>
		<FormGroup>
			<Label className="mr-4">
				<input type="radio" value="tripolis" name="externalParty" onChange={onInputChange} checked={provider !== 'webpower'} />{' '}
				<img alt="" src="/tripolis.svg" className="provider-logo" /> Tripolis
			</Label>
			<Label>
				<input type="radio" value="webpower" name="externalParty" onChange={onInputChange} checked={provider === 'webpower'} />{' '}
				<img alt="" src="/webpower.jpg" className="provider-logo" /> Webpower
			</Label>
		</FormGroup>
		<ExternalIdForm externalIds={externalIds} onInputChange={onInputChange} provider={provider} />
	</Fragment>;
}

class MailingForm extends React.Component {
	static propTypes = {
		interestTypes: PropTypes.array,
	};

	static defaultProps = {
		interestTypes: [],
	};

	componentDidMount() {
		this.props.setDefaultValue({
			plannedSend: new Date().toISOString(),
		});
		this.props.setDefaultValue('interests', []);
		this.props.setDefaultValue('externalId', []);
		this.props.setDefaultValue('externalParty', 'tripolis');
		this.props.addTrimmedFields('externalId', []);
		if(this.props.newMailing) {
			this.props.onInputChange({ target: { name: 'state', value: { label: 'Concept', value: 'draft'} }});
		}
	}

	getCurrentInterestTypes = () => {
		const typesData = this.props.getdata('interests');

		const isArrayOfStrings = Array.isArray(typesData) && typesData.every(t => typeof t === 'string');

		if (isArrayOfStrings) {
			return typesData.map(t => ({ label: t, value: t }));
		}

		return typesData;
	};

	render() {
		const {getdata, onInputChange, onNumberInputChange, onFormSubmit, hideExternal} = this.props;

		const plannedSend = getdata('plannedSend');
		const momentDate = plannedSend ? moment(getdata('plannedSend')) : moment();

		const mailingStates = Object.keys(MailingState).map(state => {
			return {
				value: MailingState[state],
				label: MailingStateText[state],
			};
		});

		const interestTypes = (this.props.interestTypes || []).map(type => {
			return {
				value: type,
				label: type,
			};
		});

		const stateValueRaw = getdata('state');
		const stateValue = typeof stateValueRaw === 'string' ? mailingStates.find(s => s.value === stateValueRaw) : stateValueRaw;
		const externalIds = getdata('externalId') || [];
		if(externalIds.every(v => v)) {
			externalIds.push('');
		}

		return <Form onSubmit={onFormSubmit}>
			<FormGroup>
				<Label>Naam</Label>
				<Input type="text" name="name" value={getdata('name')} onChange={onInputChange} />
			</FormGroup>

			<FormGroup>
				<Label>Geplande verzenddatum. Let op: dit heeft rechtstreeks gevolgen voor het MPR.</Label>
				<DatePicker
					className="form-control"
					selected={momentDate.isValid() ? momentDate.toDate() : new Date()}
					onChange={date => {
						onInputChange({
							target: {
								name: 'plannedSend',
								value: date.toISOString(),
							},
						});
					}}
					dropdownMode="scroll"
					dateFormat="dd-MM-yyyy"
				/>
			</FormGroup>

			<FormGroup>
				<Label>Bruto adresprijs</Label>
				<Input type="text" name="budget" value={getdata('budget')} onChange={onNumberInputChange} />
			</FormGroup>

			<FormGroup>
				<Label>Aantal adressen 100%</Label>
				<Input type="number" name="addresses" value={getdata('addresses')} onChange={onInputChange} />
			</FormGroup>

			<FormGroup>
				<Label>Interesses</Label>
				<Select
					value={this.getCurrentInterestTypes()}
					options={interestTypes}
					onChange={selected => onInputChange({ target: { name: 'interests', value: selected || [] }})}
					isMulti
					isSearchable
				/>
			</FormGroup>

			<FormGroup>
				<Label>Geef de status van de verzending aan.</Label>
				<Select
					value={stateValue}
					options={mailingStates}
					onChange={selected => onInputChange({ target: { name: 'state', value: selected }})}
				/>
				<FormText>
					<strong>Concept:</strong> Voor tellingen en heeft geen gevolgen voor de maildruk.<br />
					<strong>Definitief:</strong> Ingekocht en zorgt ervoor dat de adressen worden gereserveerd. Let op dat de ingeplande verzenddatum goed staat ivm. de maildruk.<br />
					<strong>Aangeboden:</strong> Verzonden en zorgt ervoor dat de verzending getoond wordt in het MPR. Let op dat de verzenddatum daadwerkelijk overeenkomt van Tripolis.<br />
					<strong>Geannuleerd:</strong> Deze haalt de verzending uit het MPR en de adressen komen vrij uit de maildruk.
				</FormText>
			</FormGroup>

			{!hideExternal && <MailProviderForm externalIds={externalIds} onInputChange={onInputChange} provider={getdata('externalParty')} />}

			<FormGroup>
				<Button type="submit" color="primary">Submit</Button>
			</FormGroup>
		</Form>;
	}
}

export default withFormUtils(MailingForm);
