import {
	SET_DATASET_HEADERS,
	SET_DATASET_ID, SET_DATASET_MAPPINGS,
	SET_GLOBAL_MAPPINGS, SET_GLOBAL_VALUE_MAPPINGS,
	SET_MAPPING_FOR_HEADER,
	SET_PARTNER_ID, SET_SELECTED_MAPPING, SET_SELECTED_MAPPING_HEADER_VALUE, SET_SELECTED_MAPPING_HEADERS,
} from '../actions/mapping-editor';

const initialState = {
	partnerId: null,
	datasetId: null,
	headers: null,
	globalMappings: [],
	mappings: [],
	selectedMapping: null,
	selectedMappingHeaders: [],
	globalValueMappings: [],
	nextMappingId: 0
};

function updateMapping(state, payload) {
	const mappings = state.mappings.slice();
	let index = mappings.findIndex(m => m.id === payload.id);
	let nextMappingId = state.nextMappingId;
	if (index >= 0) {
		const newMapping = Object.assign({}, mappings[index]);

		['mapped', 'encrypted', 'lower', 'strip', 'emailDomain', 'trim', 'date', 'exclude'].forEach(key => {
			if(key in payload) {
				newMapping[key] = payload[key];
				if(payload[key] === '') {
					delete payload[key];
				}
			}
		});

		mappings[index] = newMapping;
	} else {
		const newMapping = {
			id: nextMappingId++,
			type: 'column',
			field: payload.field,
			encrypted: payload.encrypted || false,
			lower: payload.lower || false,
			strip: payload.strip || false,
			emailDomain: payload.emailDomain || false,
		};

		if (payload.mapped) {
			newMapping.mapped = payload.mapped;
		}

		if('insertAfter' in payload) {
			index = mappings.findIndex(m => m.id === payload.insertAfter) + 1;
			mappings.splice(index, 0, newMapping);
		} else {
			index = mappings.length;
			mappings.push(newMapping);
		}
	}
	if((!state.mappings[index] || state.mappings[index].mapped !== payload.mapped) && payload.mapped === 'postcode') {
		let count = 0;
		const found = {
			pc1: false,
			pc2: false,
			pc3: false,
			pc4: false,
			pc6: false
		};
		for(const mapping of mappings) {
			if(mapping.mapped in found) {
				found[mapping.mapped] = true;
				count++;
				if(count >= Object.keys(found).length) {
					break;
				}
			}
		}
		const toAdd = [];
		Object.keys(found).forEach(key => {
			if(!found[key]) {
				toAdd.push({
					id: nextMappingId++,
					type: 'column',
					field: mappings[index].field,
					mapped: key,
					trim: +key.slice(-1),
					strip: true,
					lower: true
				});
			}
		});
		if(toAdd.length) {
			mappings.splice(index + 1, 0, ...toAdd);
		}
	}

	return Object.assign({}, state, { mappings, nextMappingId });
}

function setMappingValueForHeader(state, payload) {
	const mappings = state.mappings.slice();
	const mappingIndex = mappings.findIndex(m => m.id === payload.mapping);
	const mapping = Object.assign({}, mappings[mappingIndex]);

	if ('values' in mapping) {
		if(payload.mappings) {
			const present = new Set(mapping.values.map(v => v.original));
			mapping.values = mapping.values.slice();
			payload.mappings.forEach(v => {
				if(!present.has(v.original)) {
					mapping.values.push(v);
				}
			});
		} else {
			const valueMappingIndex = mapping.values.findIndex(v => v.original === payload.header.value);
			mapping.values = mapping.values.slice();

			if (valueMappingIndex > -1) {
				if(payload.value) {
					mapping.values[valueMappingIndex].mapped = payload.value;
				} else {
					mapping.values.splice(valueMappingIndex, 1);
				}
			} else if(payload.value) {
				mapping.values.push({
					original: payload.header.value,
					mapped: payload.value,
					insensitive: true,
				});
			}
		}
	} else if(payload.mappings) {
		mapping.values = payload.mappings;
	} else if(payload.value) {
		mapping.values = [{
			original: payload.header.value,
			mapped: payload.value,
			insensitive: true,
		}];
	}

	mappings[mappingIndex] = mapping;

	return Object.assign({}, state, { mappings });
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PARTNER_ID:
			return Object.assign({}, state, { partnerId: action.payload.partnerId });

		case SET_DATASET_ID:
			return Object.assign({}, state, { datasetId: action.payload.datasetId });

		case SET_DATASET_HEADERS:
			return Object.assign({}, state, { headers: action.payload.headers });

		case SET_GLOBAL_MAPPINGS:
			return Object.assign({}, state, { globalMappings: action.payload.globalMappings });

		case SET_MAPPING_FOR_HEADER:
			return updateMapping(state, action.payload);

		case SET_DATASET_MAPPINGS:
			return Object.assign({}, state, { mappings: action.payload.mappings, nextMappingId: action.payload.nextId });

		case SET_SELECTED_MAPPING:
			return Object.assign({}, state, { selectedMapping: action.payload.mapping });

		case SET_SELECTED_MAPPING_HEADERS:
			return Object.assign({}, state, { selectedMappingHeaders: action.payload.headers });

		case SET_SELECTED_MAPPING_HEADER_VALUE:
			return setMappingValueForHeader(state, action.payload);

		case SET_GLOBAL_VALUE_MAPPINGS:
			return Object.assign({}, state, { globalValueMappings: action.payload.mappings });

		default:
			return state;
	}
};

export default reducer;
