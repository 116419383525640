import React from 'react';
import PropTypes from 'prop-types';
import {Alert as BSAlert} from 'reactstrap';

import Message from '../../messages/Message';

export default class Alert extends React.Component {
	static propTypes = {
		message: PropTypes.instanceOf(Message).isRequired,
	};

	constructor (props) {
		super(props);
		this.state = {
			message: props.message.getMessage(),
			name: props.message.getName(),
			color: props.message.getData().color || 'info',
			time: props.message.getTime(),
			isOpen: true,
		};

		this.onDismiss = this.onDismiss.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		const time = props.message.getTime();

		if (time !== state.time) {
			return {
				message: props.message.getMessage(),
				name: props.message.getName(),
				color: props.message.getData().color || 'info',
				time: props.message.getTime(),
				isOpen: true,
			};
		}

		return null;
	}

	onDismiss () {
		this.setState({
			isOpen: false,
		});
	}

	render () {
		const { isOpen, color, name, message } = this.state;
		if (isOpen) {
			return (
				<BSAlert color={color}>
					<h5 id={name}>{name}</h5>
					{typeof mesage === 'string' ? <p>{message}</p> : message}
				</BSAlert>
			);
		}
		return null;

	}
}
