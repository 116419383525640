export default class Message {
	constructor (name, message, data = {}, time = null) {
		this.name = name;
		this.message = message;
		this.data = data;
		this.time = time || Date.now();
	}

	getName () {
		return this.name;
	}

	getMessage () {
		return this.message;
	}

	getData () {
		return this.data;
	}

	getTime () {
		return this.time;
	}

	toString () {
		return JSON.stringify({
			name: this.getName(),
			message: this.getMessage(),
			data: this.getData(),
			time: this.getTime(),
		});
	}
}
