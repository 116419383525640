export const SET_PAGINATOR_PAGE = 'SET_PAGINATOR_PAGE';

export function setPaginatorPage(name, pageNum) {
	return {
		type: SET_PAGINATOR_PAGE,
		payload: {
			name,
			pageNum,
		},
	};
}
