import axios from 'axios';
import HttpStatus from 'http-status-codes';
import { store } from './redux/store';
import { setAccount } from './redux/actions/auth';
import { sortStrings } from './util/array';

const e = str => encodeURIComponent(str);

const client = axios.create({
	baseURL: '/api',
	headers: {
		'Content-Type': 'application/json',
	},
});

/**
 * Fire a request to the API
 *
 * @param {string} url
 * @param {string} method
 * @param {Object} data
 */

function doRequest(url, method = 'GET', data = {}, params) {
	return client({
		url,
		method,
		data,
		params
	}).catch(err => {
		if(err.response && (err.response.status === HttpStatus.UNAUTHORIZED || err.response.status === HttpStatus.FORBIDDEN)) {
			store.dispatch(setAccount(null));
		}
		return Promise.reject(err);
	});
}

function formatError(err) {
	const {data} = err.response;

	if ('error' in data) {
		return Promise.reject(new Error(data.error));
	} else if ('message' in data) {
		return Promise.reject(new Error(data.message));
	}

	return Promise.reject(new Error(err.message));
}

export function fetchPartnerBlacklist(id) {
	return doRequest(`/partnerblacklist/${id}`, 'GET')
		.then(res => res.data);
}

export function getCounts(useBlacklist = false) {
	return doRequest('/count', 'GET', {}, {
		useBlacklist
	}).then(res => res.data);
}

export function createNewPartner(partner) {
	return doRequest('/crud-partner', 'POST', partner);
}

export function fetchPartners() {
	return doRequest('/crud-partner')
		.then(res => res.data);
}

export function fetchPartner(id) {
	return doRequest(`/crud-partner/${e(id)}`)
		.then(res => res.data);
}

export function updatePartner(id, partner) {
	return doRequest(`/crud-partner/${e(id)}`, 'PUT', partner);
}

export function deletePartner(id) {
	return doRequest(`/crud-partner/${e(id)}`, 'DELETE');
}

export function createNewPartnerContact(partnerId, contact) {
	return doRequest(`/crud-partner/${e(partnerId)}/contacts`, 'POST', contact);
}

export function fetchPartnerContact(partnerId, contactId) {
	return doRequest(`/crud-partner/${e(partnerId)}/contacts/${e(contactId)}`)
		.then(res => res.data);
}

export function updatePartnerContact(partnerId, contactId, contact) {
	return doRequest(`/crud-partner/${e(partnerId)}/contacts/${e(contactId)}`, 'PUT', contact);
}

export function deletePartnerContact(partnerId, contactId) {
	return doRequest(`/crud-partner/${e(partnerId)}/contacts/${e(contactId)}`, 'DELETE');
}

export function fetchDatasets(partnerId) {
	return doRequest(`/datasets/${e(partnerId)}`)
		.then(res => res.data);
}

export function createDataset(partnerId, dataset) {
	return doRequest(`/datasets/${e(partnerId)}`, 'POST', dataset)
		.catch(err => formatError(err));
}

export function fetchDataset(partnerId, datasetId) {
	return doRequest(`/datasets/${e(partnerId)}/${e(datasetId)}`)
		.then(res => res.data);
}

export function updateDataset(partnerId, datasetId, dataset) {
	return doRequest(`/datasets/${e(partnerId)}/${e(datasetId)}`, 'PUT', dataset);
}

export function deleteDataset(partnerId, datasetId) {
	return doRequest(`/datasets/${e(partnerId)}/${e(datasetId)}`, 'DELETE');
}

export function fetchDatasetHeaders(partnerId, datasetId) {
	return doRequest(`/datasets/${e(partnerId)}/${e(datasetId)}/headers`)
		.then(res => res.data);
}

export function fetchGlobalDatasetHeaderMappings() {
	return doRequest('/global-mappings')
		.then(res => res.data.sort(sortStrings));
}

export function upsertDatasetHeaderMappings(partnerId, datasetId, mappings) {
	return doRequest(`/datasets/${e(partnerId)}/${e(datasetId)}/mappings`, 'PUT', mappings);
}

export function fetchDatasetHeaderMappings(partnerId, datasetId) {
	return doRequest(`/datasets/${e(partnerId)}/${e(datasetId)}/mappings`)
		.then(res => res.data);
}

export function fetchCSV(partnerId, datasetId) {
	return doRequest(`/datasets/${e(partnerId)}/${e(datasetId)}/csv`)
		.then(res => res.data);
}

export function fetchUniqueDatasetValues(partnerId, datasetId, omgColumnName) {
	return doRequest(`/datasets/${e(partnerId)}/${e(datasetId)}/${e(omgColumnName)}/unique-values`)
		.then(res => res.data);
}

export function fetchGlobalValueMappings(mapping) {
	return doRequest(`/value-mappings/${e(mapping)}`)
		.then(res => res.data);
}

export function getValueMappings(mapping) {
	return doRequest(`/value-mappings/${e(mapping)}/copy`)
		.then(res => res.data);
}

export function startRemap(partnerId, datasetId) {
	return doRequest(`/datasets/${e(partnerId)}/${e(datasetId)}/remap`, 'POST')
		.then(res => res.data.task);
}

// #region types
export function fetchTypes() {
	return doRequest('/types')
		.then(res => res.data.sort(sortStrings));
}

export function createNewType(data) {
	return doRequest('/types', 'POST', data);
}

export function updateType(type, data) {
	return doRequest(`/types/${e(type)}`, 'PUT', data);
}

export function deleteType(type) {
	return doRequest(`/types/${e(type)}`, 'DELETE');
}
// #endregion

export function fetchCampaigns(days) {
	return doRequest('/campaigns', 'GET', undefined, { days })
		.then(res => res.data);
}

export function fetchPagCampaigns(page, displayItems) {
	return doRequest(`/pagcampaigns/${e(page)}/${e(displayItems)}`)
		.then(res => res.data);
}

export function fetchPagCampaignsSearch(page, displayItems, search) {
	return doRequest(`/pagcampaignsearch/${e(page)}/${e(displayItems)}/${e(search)}`)
		.then(res => res.data);
}

export function fetchCampaign(id, noCount) {
	return doRequest(`/campaigns/${e(id)}`, 'GET', undefined, { noCount })
		.then(res => res.data);
}

export function createNewCampaign(data) {
	return doRequest('/campaigns', 'POST', data).then(res => res.data);
}

export function updateCampaign(id, data) {
	return doRequest(`/campaigns/${e(id)}`, 'PUT', data);
}

export function deleteCampaign(id) {
	return doRequest(`/campaigns/${e(id)}`, 'DELETE');
}

// #region mailings
export function fetchMailing(campaignId, mailingId) {
	return doRequest(`/campaigns/${e(campaignId)}/mailings/${e(mailingId)}`)
		.then(res => res.data);
}

export function createNewMailing(campaignId, data) {
	return doRequest(`/campaigns/${e(campaignId)}/mailings`, 'POST', data);
}

export function updateMailing(campaignId, mailingId, data) {
	return doRequest(`/campaigns/${e(campaignId)}/mailings/${e(mailingId)}`, 'PUT', data);
}

export function deleteMailing(campaignId, mailingId) {
	return doRequest(`/campaigns/${e(campaignId)}/mailings/${e(mailingId)}`, 'DELETE');
}

export function limitMailing(campaignId, mailingId, limit) {
	return doRequest(`/campaigns/${e(campaignId)}/mailings/${e(mailingId)}/limit`, 'POST', {
		limit,
	});
}

export function getMailingPressure(campaignId, mailingId) {
	return doRequest(`/campaigns/${e(campaignId)}/mailings/${e(mailingId)}/pressure`).then(res => res.data);
}

export function deleteMailingPressure(campaignId, mailingId) {
	return doRequest(`/campaigns/${e(campaignId)}/mailings/${e(mailingId)}/pressure`, 'DELETE').then(res => res.data);
}
// #endregion

/**
 * Upload a new selection
 *
 * @param {string} campaignId
 * @param {string} mailingId
 * @param {object} data
 */
export function createNewMailingSelection(campaignId, mailingId, data) {
	return doRequest(`/campaigns/${e(campaignId)}/mailings/${e(mailingId)}/selections`, 'POST', data);
}

export function performQueryFromSelection(campaignId, mailingId, selectionId) {
	return doRequest(`/campaigns/${e(campaignId)}/mailings/${e(mailingId)}/selections/${e(selectionId)}/query`)
		.then(res => res.data);
}

/**
 * Update an existing selection
 *
 * @param {string} campaignId
 * @param {string} mailingId
 * @param {string} selectionId
 * @param {object} data
 */
export function updateSelection(campaignId, mailingId, selectionId, data) {
	return doRequest(`/campaigns/${e(campaignId)}/mailings/${e(mailingId)}/selections/${e(selectionId)}`, 'PUT', data);
}

export function uploadCSVForIntersect(campaignId, mailingId, selectionId, queryId, file, mappings) {
	const formData = new FormData();
	formData.append('file', file, file.name);
	formData.append('mappings', JSON.stringify(mappings));

	return client({
		url: `/campaigns/${e(campaignId)}/mailings/${e(mailingId)}/selections/${e(selectionId)}/${queryId}/csv`,
		method: 'POST',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data: formData,
	}).then(res => res.data);
}

export function fetchSelections(campaignId, mailingId) {
	return fetchMailing(campaignId, mailingId).then(res => res.selections);
}

export function fetchPredefinedSelections() {
	return doRequest('/pre-queries').then(res => res.data);
}

export function copyMailing(campaignId, mailingId, data) {
	return doRequest(`/campaigns/${e(campaignId)}/mailings/${e(mailingId)}/selections`, 'POST', data);
}

export function saveMailing(campaignId, mailingId, selectionId, mailing) {
	return doRequest(`/campaigns/${e(campaignId)}/mailings/${e(mailingId)}/selections/${e(selectionId)}/query`, 'POST', {
		table: mailing,
	}).then(res => res.data);
}

export function deleteSelection(campaignId, mailingId, selectionId) {
	return doRequest(`/campaigns/${e(campaignId)}/mailings/${e(mailingId)}/selections/${e(selectionId)}`, 'DELETE');
}


// #region authentication
export function login(username, password, token) {
	return doRequest('/auth/login', 'POST', { username, password, token });
}

export function checkLogin() {
	return doRequest('/auth/me').then(res => res.data);
}

export function logout() {
	return doRequest('/auth/logout', 'POST');
}
// #endregion

// #region users
export function fetchUsers() {
	return doRequest('/users')
		.then(res => res.data);
}

export function createUser(data) {
	return doRequest('/users', 'POST', data);
}

export function updateUser(userId, data) {
	return doRequest(`/users/${e(userId)}`, 'PUT', data);
}

export function deleteUser(userId) {
	return doRequest(`/users/${e(userId)}`, 'DELETE');
}
// #endregion

// #region blacklist
export function fetchEmailBlacklistLength() {
	return doRequest('/blacklist/email/count')
		.then(res => res.data);
}

export function fetchDomainBlacklist() {
	return doRequest('/blacklist/domain')
		.then(res => res.data);
}

export function submitEmailToBlacklist(file, emailColumn) {
	const formData = new FormData();
	formData.append('file', file, file.name);
	formData.append('emailColumn', emailColumn);

	return client({
		url: '/blacklist/emailimport',
		method: 'POST',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data: formData,
	}).then(res => res.data);
}

export function submitDomainToBlacklist(file, domainColumn) {
	const formData = new FormData();
	formData.append('file', file, file.name);
	formData.append('domainColumn', domainColumn);

	return client({
		url: '/blacklist/domainimport',
		method: 'POST',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data: formData,
	}).then(res => res.data);

}

export function submitPartnerBlacklist(file, emailColumn, partnerColumn, datasetColumn) {
	const formData = new FormData();
	formData.append('file', file, file.name);
	formData.append('emailColumn', emailColumn);
	formData.append('partnerColumn', partnerColumn);
	formData.append('datasetColumn', datasetColumn);

	return client({
		url: '/partnerblacklist/import',
		method: 'POST',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data: formData,
	}).then(res => res.data);
}

export function deleteEmailFromBlacklist(email) {
	return doRequest(`/blacklist/email/${e(email)}`, 'DELETE');
}


export function deleteDomainFromBlacklist(domain) {
	return doRequest(`/blacklist/domain/${e(domain)}`, 'DELETE');
}
// #endregion

// #region dataset extras
export function addDatasetExtra(partnerId, datasetId, type, data) {
	return doRequest(`/datasets/${e(partnerId)}/${e(datasetId)}/${type}`, 'POST', data);
}

export function updateDatasetExtra(partnerId, datasetId, type, start, end, data) {
	return doRequest(`/datasets/${e(partnerId)}/${e(datasetId)}/${type}/${e(start)}/${e(end)}`, 'PUT', data);
}

export function deleteDatasetExtra(partnerId, datasetId, type, start, end) {
	return doRequest(`/datasets/${e(partnerId)}/${e(datasetId)}/${type}/${e(start)}/${e(end)}`, 'DELETE');
}
// #endregion

// #region tasks
export function pollTask(taskId) {
	return doRequest(`/tasks/${taskId}`)
		.then(res => res.data);
}
// #endregion

export function importDataset(partnerId, datasetName, file) {
	const formData = new FormData();
	formData.append('file', file, file.name);

	return client({
		url: `/import/${e(partnerId)}/${e(datasetName)}`,
		method: 'POST',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data: formData,
	}).then(res => res.data);
}

// #region click interest types
export function fetchInterestTypes() {
	return doRequest('/click-interest-types')
		.then(res => res.data.sort(sortStrings));
}

export function fetchPagInterestTypes(page, displayItems) {
	return doRequest(`paginated-click-interest-types/${e(page)}/${e(displayItems)}`)
		.then(res => res.data);
}

export function fetchPagInterestTypesSearch(page, displayItems, search) {
	return doRequest(`/paginated-click-interest-types-search/${e(page)}/${e(displayItems)}/${e(search)}`)
		.then(res => res.data);
}

export function createClickInterestType(data) {
	return doRequest('/click-interest-types', 'POST', data);
}

export function deleteClickInterestType(id) {
	return doRequest(`/click-interest-types/${e(id)}`, 'DELETE');
}
// #endregion

export function getTasks() {
	return doRequest('/tasks').then(res => res.data);
}

export function getConnectorStatus() {
	return doRequest('/connector/status').then(res => res.data);
}

export function getColumns() {
	return doRequest('/export/columns').then(res => res.data);
}

export function deleteColumn(column) {
	return doRequest('/export/columns', 'DELETE', {}, { column });
}

export function addColumn(column) {
	return doRequest('/export/columns', 'POST', column);
}

export function getMailingSettings() {
	return doRequest('/settings').then(res => res.data);
}

export function setMailingSettings(settings) {
	return doRequest('/settings', 'POST', settings);
}

export function getMailPressure() {
	return doRequest('/pressure').then(res => res.data);
}

export function getVersion() {
	return doRequest('/version').then(res => res.data);
}

export function uploadEnrichmentFile(name, file, mappings) {
	const data = new FormData();
	data.append('name', name);
	data.append('file', file, file.name);
	data.append('mappings', JSON.stringify(mappings));
	return client({
		url: '/enrichment/files',
		method: 'POST',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data
	}).then(res => res.data);
}

export function getEnrichmentFiles() {
	return doRequest('/enrichment/files').then(res => res.data);
}

export function deleteFile(id) {
	return doRequest(`/enrichment/files/${e(id)}`, 'DELETE');
}

export function getValueMappedFields() {
	return doRequest('/valuemapped').then(res => res.data);
}

export function getValueMappingDatasets(field) {
	return doRequest(`/valuemapped/${encodeURIComponent(field)}`).then(res => res.data);
}

export function deleteFromDataset(partnerId, datasetName, file, emailColumn) {
	const formData = new FormData();
	formData.append('file', file, file.name);
	formData.append('emailColumn', emailColumn);
	return client({
		url: `/deleteall/${e(partnerId)}/${e(datasetName)}`,
		method: 'POST',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data: formData,
	}).then(res => res.data);
}

export function checkDNS() {
	return doRequest('/dns-check', 'POST').then(res => res.data.task);
}

export function blacklistDomains(domains) {
	return doRequest('/blacklist/domains', 'POST', { domains });
}

export function deleteTestPerson(partnerId, datasetId, personId) {
	return doRequest(`/datasets/${e(partnerId)}/${e(datasetId)}/testpersons/${e(personId)}`, 'DELETE');
}

export function createTestPerson(partnerId, datasetId, testPerson) {
	return doRequest(`/datasets/${e(partnerId)}/${e(datasetId)}/testpersons`, 'POST', testPerson);
}

export function getTestPerson(partnerId, datasetId, personId) {
	return doRequest(`/datasets/${e(partnerId)}/${e(datasetId)}/testpersons/${e(personId)}`).then(res => res.data);
}

export function updateTestPerson(partnerId, datasetId, personId, testPerson) {
	return doRequest(`/datasets/${e(partnerId)}/${e(datasetId)}/testpersons/${e(personId)}`, 'PUT', testPerson);
}

export function getGlobalTestPerson() {
	return doRequest('/testperson').then(res => res.data);
}

export function setGlobalTestPerson(testPerson) {
	return doRequest('/testperson', 'PUT', testPerson);
}

export function enable2FA(user, username) {
	return doRequest(`/auth/2fa/${user}`, 'POST', { username }).then(res => res.data.link);
}

export function disable2FA(user) {
	return doRequest(`/auth/2fa/${user}/disable`, 'PUT');
}
