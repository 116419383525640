import {SET_USERS} from '../actions/user';

const initialState = {
	users: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_USERS:
			return Object.assign({}, state, { users: action.payload.users });

		default:
			return state;
	}
};

export default reducer;
