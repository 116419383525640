import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Table, Form, FormGroup, Container, Button, Input, InputGroup, InputGroupAddon} from 'reactstrap';
import InputAutocomplete from '../../Autocomplete';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Alert from '../../alert/Alert';
import PaginatorContainer from '../../../paginator/PaginatorContainer';
import {withMessages} from '../../../hoc/with-messages';
import FilterContainer from '../../../containers/FilterContainer';
import {makeRegExp} from '../../../util/string';

import Message from '../../../messages/Message';

const EDITOR_MESSAGE = 'EDITOR_MESSAGE';

const MAX_DISPLAY_ITEMS = 10;

export default withMessages(class MappingEditor extends React.Component {
	static propTypes = {
		onSubmit: PropTypes.func.isRequired,
		filters: PropTypes.array.isRequired,
		headers: PropTypes.array.isRequired,
		globalMappings: PropTypes.array.isRequired,
		onMappingChange: PropTypes.func.isRequired,
		mappings: PropTypes.array.isRequired,
		message: PropTypes.instanceOf(Message),
		mappingFieldsReadOnly: PropTypes.bool,
		onRemapButtonClick: PropTypes.func.isRequired,
		remapButtonText: PropTypes.string.isRequired,
		remapButtonEnabled: PropTypes.bool,
		datasetId: PropTypes.string.isRequired,
		partnerId: PropTypes.string.isRequired,
	};

	static defaultProps = {
		message: null,
		mappingFieldsReadOnly: false,
		remapButtonEnabled: true,
	};

	constructor(props) {
		super(props);

		this.state = {
			headers: null,
			mappings: null,
			onlyShowError: false,
			values: {},
			fetchedValues: {},
			showToolTip: false,
		};

		this.renderMapping = this.renderMapping.bind(this);
	}

	getValue(mapping, key, def = '') {
		if (mapping && key in mapping) {
			return mapping[key];
		}

		return def;
	}

	getFilteredMappings() {
		const mappings = this.props.mappings.filter(m => m.type === 'column');
		const filter = this.props.filters.find(f => f.name === this.props.datasetId);

		if (filter && filter.value) {
			const pattern = makeRegExp(filter.value, 'i');
			return mappings.filter(m => m.type === 'column' && pattern.test(m.field));
		}

		return mappings;
	}

	downloadValueMappings(mapping) {
		return e => {
			e.preventDefault();
			this.props.downloadValueMappings(mapping);
		};
	}

	renderMapping(mapping) {
		const value = this.getValue(mapping, 'mapped');
		return <tr key={mapping.id}>
			<td>
				{this.renderHeaderInput(mapping)}
			</td>

			<td>
				<InputAutocomplete
					items={this.props.globalMappings}
					disabled={this.props.mappingFieldsReadOnly}
					value={value}
					onChange={e => this.props.onMappingChange(mapping, { mapped: e.target.value })}
					shouldItemRender={(item, val) => item.toLowerCase().includes(val.toLowerCase())}
				/>
			</td>

			<td className="text-center">
				<Button color="primary" disabled={!value} onClick={this.downloadValueMappings(mapping)}>
					<FontAwesomeIcon icon="file-download" />
				</Button>
			</td>
			<td className="text-center">
				<Button color="success" onClick={e => {
					e.preventDefault();
					this.props.onMappingChange(null, { field: mapping.field, insertAfter: mapping.id });
				}}>
					<FontAwesomeIcon icon="plus" />
				</Button>
			</td>

			<td>
				<Input
					type="text"
					value={this.getValue(mapping, 'date', '')}
					onChange={e => this.props.onMappingChange(mapping, { date: e.target.value })}
				/>
			</td>
			<td>
				<Input
					type="text"
					value={this.getValue(mapping, 'exclude', '')}
					onChange={e => this.props.onMappingChange(mapping, { exclude: e.target.value })}
				/>
			</td>
			<td>
				<Input
					min={0}
					type="number"
					value={this.getValue(mapping, 'trim', '')}
					onChange={e => {
						if(e.target.value === '') {
							this.props.onMappingChange(mapping, { trim: '' });
						} else if(!isNaN(e.target.value) && Number.isInteger(+e.target.value)) {
							this.props.onMappingChange(mapping, { trim: +e.target.value });
						}
					}}
				/>
			</td>
			<td className="text-center">
				<input
					type="checkbox"
					checked={this.getValue(mapping, 'encrypted', false)}
					onChange={e => this.props.onMappingChange(mapping, { encrypted: e.target.checked })}
				/>
			</td>
			<td className="text-center">
				<input
					type="checkbox"
					checked={this.getValue(mapping, 'lower', false)}
					onChange={e => this.props.onMappingChange(mapping, { lower: e.target.checked })}
				/>
			</td>
			<td className="text-center">
				<input
					type="checkbox"
					checked={this.getValue(mapping, 'strip', false)}
					onChange={e => this.props.onMappingChange(mapping, { strip: e.target.checked })}
				/>
			</td>
			<td className="text-center">
				<input
					type="checkbox"
					checked={this.getValue(mapping, 'emailDomain', false)}
					onChange={e => this.props.onMappingChange(mapping, { emailDomain: e.target.checked })}
				/>
			</td>
		</tr>;
	}

	renderHeaderInput(mapping) {
		const input = <Input type="text" readOnly value={mapping.field} />;

		if (mapping.mapped && !mapping.encrypted) {
			return <InputGroup>
				{input}
				<InputGroupAddon addonType="append">
					<Link className="btn btn-info" to={`/partners/${this.props.partnerId}/datasets/${this.props.datasetId}/${mapping.id}/mappings`}>Map kenmerken</Link>
				</InputGroupAddon>
			</InputGroup>;
		}

		return input;
	}

	render() {
		const {message} = this.props;
		const editorMessage = this.props.messages[EDITOR_MESSAGE];
		const alert = editorMessage && <Alert message={editorMessage} />;
		const alertFromProps = message && <Alert message={message} />;

		if (this.state.onlyShowError) {
			return alert;
		}

		return <div id="dataset-header-editor">
			<Container>
				<h1>Map bestanden naar bekende kolommen</h1>

				{alert}
				{alertFromProps}
			</Container>

			<Container className="text-left" fluid>
				<FilterContainer name={this.props.datasetId} />

				<Form onSubmit={this.props.onSubmit}>
					<Table borderless>
						<thead>
							<tr>
								<th colSpan={4} />
								<th className="text-center">
									Datum formaat
								</th>
								<th className="text-center">
									Filter
								</th>
								<th className="text-center">
									Inkorten
								</th>
								<th className="text-center">
									<FontAwesomeIcon icon="lock" title="Encrypt" />
								</th>
								<th className="text-center">
									<span title="Kleine letters">Aa</span>
								</th>
								<th className="text-center">
									<span title="Wituimte weghalen">_</span>
								</th>
								<th className="text-center">
									<span title="Domein splitsen">@</span>
								</th>
							</tr>
						</thead>
						<tbody>
							<PaginatorContainer
								name={this.props.datasetId}
								items={this.getFilteredMappings()}
								renderItem={this.renderMapping}
								seed={Date.now()}
								maxDisplayItems={MAX_DISPLAY_ITEMS}
							/>
						</tbody>
					</Table>

					<FormGroup>
						<Button color="primary" type="submit">Bewaar</Button>
						<Link className="btn btn-info ml-2" to={`/partners/${this.props.partnerId}/datasets`}>Terug naar bestanden</Link>
						<Button className="ml-2" color="info" onClick={this.props.onRemapButtonClick} disabled={!this.props.remapButtonEnabled}>{this.props.remapButtonText}</Button>
					</FormGroup>

				</Form>
				{
					this.state.showToolTip
						?
						<div className="mb-2">
							Geef in de datumkolom de notatie van datumvelden zoals ze in het databestand zijn genoteerd.<br />
							Datums worden vervolgens in het formaat YYYY-MM-DD HH:mm:ss opgeslagen. <br />
							<i>Let op hoofdlettergevoeligheid.</i><br />
							<table className="mt-2">
								<tr>
									<th className="col-6">Invoer</th>
									<th className="col-6">Beschrijving</th>
								</tr>
								<tr>
									<td className="col-6 border-top">
								YYYY
									</td>
									<td className="col-6 border-top">
								Jaar
									</td>
								</tr>
								<tr>
									<td className="col-6">
								MM
									</td>
									<td className="col-6">
								Maand
									</td>
								</tr>
								<tr>
									<td className="col-6">
								DD
									</td>
									<td className="col-6">
								Dag
									</td>
								</tr>
								<tr>
									<td className="col-6 border-top">
								gggg
									</td>
									<td className="col-6 border-top">
								Week jaar
									</td>
								</tr>
								<tr>
									<td className="col-6">
								w / ww
									</td>
									<td className="col-6">
								Week nummer
									</td>
								</tr>
								<tr>
									<td className="col-6">
								MMM MMMM
									</td>
									<td className="col-6">
								Maand naam (Jan ... December)
									</td>
								</tr>
								<tr>
									<td className="col-6">
								ddd dddd
									</td>
									<td className="col-6">
										{'Dagnaam (Mon ... Sunday)'}
									</td>
								</tr>
								<tr>
									<td className="col-6 border-top">
									HH
									</td>
									<td className="col-6 border-top">
									Uur
									</td>
								</tr>
								<tr>
									<td className="col-6">
									mm
									</td>
									<td className="col-6">
									Minuten
									</td>
								</tr>
								<tr>
									<td className="col-6">
									ss
									</td>
									<td className="col-6">
									Seconden
									</td>
								</tr>
							</table>
						</div>
						:
						<p className="text-primary clickable" onClick={() => this.setState({ showToolTip: true})}><u>Datum formaat</u></p>
				}
			</Container>
		</div>;
	}
});
