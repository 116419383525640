import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import * as reducers from './reducers';

const customReducers = Object.keys(reducers).reduce((currentReducers, reducerName) => {
	currentReducers[reducerName.replace('Reducer', '')] = reducers[reducerName];
	return currentReducers;
}, {});

export const store = createStore(
	combineReducers(customReducers),
	compose(
		applyMiddleware(
			thunk
		)
	)
);
