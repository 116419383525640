export const UPDATE_FILTER = 'UPDATE_FILTER';
export const DELETE_FILTER = 'DELETE_FILTER';

export function updateFilter(name, value) {
	return {
		type: UPDATE_FILTER,
		payload: {
			name,
			value,
		},
	};
}

export function deleteFilter(name) {
	return {
		type: DELETE_FILTER,
		payload: {
			name,
		},
	};
}
