import {SET_INTEREST_TYPES} from '../actions/interest-types';

const initialState = {
	interestTypes: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_INTEREST_TYPES:
			return {...state, interestTypes: action.payload.types };

		default:
			return state;
	}
};

export default reducer;
