import {SET_TYPES} from '../actions/types';

const initialState = {
	types: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_TYPES:
			return Object.assign({}, state, { types: action.payload.types });

		default:
			return state;
	}
};

export default reducer;
