import {DELETE_FILTER, UPDATE_FILTER} from '../actions/filters';

const initialState = {
	filters: [],
};

function getOrCreateFilter(state, name) {
	const filter = state.filters.find(f => f.name === name);

	if (filter) {
		return filter;
	}

	return {
		name,
		value: '',
	};
}

function updateFilters(state, filter, shouldDelete = false) {
	const filters = state.filters.slice();
	const filterIndex = filters.findIndex(f => f.name === filter.name);

	if (filterIndex !== -1) {
		if (shouldDelete) {
			filters.splice(filterIndex, 1);
		} else {
			filters.splice(filterIndex, 1, filter);
		}
	} else {
		filters.push(filter);
	}

	return filters;
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_FILTER: {
			const {name, value} = action.payload;
			const filter = Object.assign({}, getOrCreateFilter(state, name));
			filter.value = value;

			const filters = updateFilters(state, filter);
			return Object.assign({}, state, { filters });
		}

		case DELETE_FILTER: {
			const {name} = action.payload;
			const filter = getOrCreateFilter(state, name);
			const filters = updateFilters(state, filter, true);
			return Object.assign({}, state, { filters });
		}

		default:
			return state;
	}
};

export default reducer;
