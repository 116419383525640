export const SET_USERS = 'SET_USERS';

export function setUsers(users) {
	return {
		type: SET_USERS,
		payload: {
			users,
		},
	};
}
