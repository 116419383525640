import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Switch, Route} from 'react-router-dom';
import { history } from '../history';

import {withCampaigns} from '../hoc/with-campaigns';
import UpdateOrCreateItem from '../components/crud/UpdateOrCreateItem';
import MailingForm from '../components/mailings/MailingForm';

import * as api from '../api';
import {withMessages} from '../hoc/with-messages';
import {clearSelectedMailing, setSelectedMailing, setCampaigns} from '../redux/actions/campaigns';

import {withPrompts} from '../hoc/with-prompts';
import ListMailings from '../components/mailings/ListMailings';
import CopyMailingForm from '../components/mailings/CopyMailingForm';
import {withLoader} from '../hoc/with-loader';
import { setInterestTypes } from '../redux/actions/interest-types';

const LIST_MAILING_MESSAGE = 'list-mailing-message';
const NEW_MAILING_MESSAGE = 'new-mailing-message';
const EDIT_MAILING_MESSAGE = 'edit-mailing-message';
const COPY_MAILING_MESSAGE = 'copy-mailing-message';

class MailingContainer extends React.Component {
	static propTypes = {
		match: PropTypes.shape({
			params: PropTypes.shape({
				campaignId: PropTypes.string,
				mailingId: PropTypes.string,
			}),
		}),
		fetchCampaigns: PropTypes.func.isRequired,
		fetchCampaign: PropTypes.func.isRequired,
		clearCampaign: PropTypes.func.isRequired,
		fetchCampaignsError: PropTypes.string,
		fetchCampaignError: PropTypes.string,
	};

	componentDidMount() {
		const {campaignId, mailingId} = this.props.match.params;

		const hasCampaign = campaignId !== undefined && campaignId !== null;
		const hasMailing = mailingId !== undefined && mailingId !== null && mailingId !== 'new';

		if (hasCampaign && hasMailing) {
			this.fetchMailing(campaignId, mailingId);
		}

		this.fetchInterestTypes();
	}

	componentDidUpdate(prevProps) {
		const currentMailingId = this.props.match.params.mailingId;
		const prevMailingId = prevProps.match.params.mailingId;

		if (currentMailingId === undefined || currentMailingId === null || currentMailingId === 'new') {
			this.props.dispatch(clearSelectedMailing());
		} else if (currentMailingId !== prevMailingId) {
			this.fetchMailing(this.props.match.params.campaignId, currentMailingId);
		}
	}

	fetchInterestTypes = () => {
		api.fetchInterestTypes().then(types => {
			this.props.dispatch(setInterestTypes(types));
		}).catch(err => {
			this.props.setMessage(LIST_MAILING_MESSAGE, 'Interesses konden niet worden opgehaald', err.response.data.message, {
				color: 'danger',
			});
		});
	};

	fetchMailing = (campaignId, mailingId) => {
		api.fetchMailing(campaignId, mailingId).then(mailing => {
			this.props.dispatch(setSelectedMailing(mailing));
		}).catch(err => {
			this.props.setMessage(EDIT_MAILING_MESSAGE, 'Mailing kon niet worden opgehaald', err.response.data.message, {
				color: 'danger',
			});
		});
	};

	fetchCampaigns = () => api.fetchCampaigns()
		.then(campaigns => this.props.dispatch(setCampaigns(campaigns)))
		.catch(err => {
			this.props.setMessage(COPY_MAILING_MESSAGE, 'Kon campagnes niet ophalen', err.response.data.message, {
				color: 'danger',
			});
		});;

	onNewMailingSubmit = data => {
		data.interests = data.interests.map(interest => {
			if (interest.value) {
				return interest.value;
			}

			return interest;
		});

		try {
			data.state = data.state.value;
		} catch (e) {
			// Fail silently
		}

		api.createNewMailing(this.props.selectedCampaign._id, data).then(() => {
			this.props.setMessage(LIST_MAILING_MESSAGE, 'Mailing succesvol aangemaakt', 'De mailing was succesvol aangemaakt', {
				color: 'success',
			});
			history.push(`/campaigns/${this.props.selectedCampaign._id}/mailings`);
			this.props.fetchCampaign(this.props.selectedCampaign._id);
		}).catch(err => {
			this.props.setMessage(NEW_MAILING_MESSAGE, 'Fout tijdens aanmaken van mailing', err.response.data.message, {
				color: 'danger',
			});
		});
	};

	onUpdateMailingSubmit = (campaignId, mailingId, data) => {
		data.interests = data.interests.map(interest => {
			if (interest.value) {
				return interest.value;
			}

			return interest;
		});

		try {
			data.state = data.state.value;
		} catch (e) {
			// Fail silently
		}

		api.updateMailing(campaignId, mailingId, data).then(() => {
			this.props.setMessage(LIST_MAILING_MESSAGE, 'Mailing succesvol opgeslagen', 'De mailing was succesvol opgeslagen', {
				color: 'success',
			});
			history.push(`/campaigns/${this.props.selectedCampaign._id}/mailings`);
			this.props.fetchCampaign(this.props.selectedCampaign._id);
		}).catch(err => {
			this.props.setMessage(EDIT_MAILING_MESSAGE, 'Fout tijdens opslaan van mailing', err.response.data.message, {
				color: 'danger',
			});
		});
	};

	deleteMailing = (campaignId, mailingId) => {
		api.deleteMailing(campaignId, mailingId).then(() => {
			this.props.setMessage(LIST_MAILING_MESSAGE, 'Mailing succesvol verwijderd', 'De mailing was succesvol verwijderd', {
				color: 'success',
			});
			this.props.fetchCampaign(campaignId);
		}).catch(err => {
			this.props.setMessage(LIST_MAILING_MESSAGE, 'Fout tijdens verwijderen van mailing', err.response.data.message, {
				color: 'danger',
			});
		});
	};

	onCopySubmit = data => {
		api.copyMailing(this.props.selectedCampaign._id, this.props.selectedMailing._id, data)
			.then(() => {
				this.props.setMessage(LIST_MAILING_MESSAGE, 'Kopie succesvol', 'De mailing is gekopieërd', {
					color: 'success',
				});
				history.push(`/campaigns/${this.props.selectedCampaign._id}/mailings`);
			}).catch(err => {
				this.props.setMessage(COPY_MAILING_MESSAGE, 'Kon de mailing niet kopiëren', err.response.data.message, {
					color: 'danger',
				});
			});
	};

	onLimitSubmit = (campaignId, mailingId, limit) => {
		this.props.setMessage(LIST_MAILING_MESSAGE, 'Maximeren', 'Verzending wordt gemaximeerd, een moment geduld...', {
			color: 'info',
		});

		api.limitMailing(campaignId, mailingId, limit).then(res => {
			const {deleted} = res.data;

			this.props.setMessage(LIST_MAILING_MESSAGE, 'Succesvol gemaximeerd', `Er zijn ${deleted} e-mails verwijderd uit de verzending`, {
				color: 'success',
			});

			this.props.fetchCampaign(campaignId);
		}).catch(err => {
			this.props.setMessage(LIST_MAILING_MESSAGE, 'Kon niet maximeren', err.response.data.message, {
				color: 'danger',
			});
		});
	};

	onPressureSubmit(campaignId, mailingId) {
		this.props.setMessage(LIST_MAILING_MESSAGE, 'Maildruk verwijderen', 'Adressen die binnen de maildruk vallen worden verwijderd...', {
			color: 'info',
		});
		api.deleteMailingPressure(campaignId, mailingId).then(({ deleted }) => {
			this.props.setMessage(LIST_MAILING_MESSAGE, 'Succesvol aangepast', `Er zijn ${deleted} e-mails verwijderd uit de verzending`, {
				color: 'success',
			});
			this.props.fetchCampaign(campaignId);
		}).catch(err => {
			this.props.setMessage(LIST_MAILING_MESSAGE, 'Kon niet verwijderen', err.response.data.message, {
				color: 'danger',
			});
		});
	}

	renderItems = () =>
		<Switch>
			<Route path="/campaigns/:campaignId/mailings/:mailingId/copy" render={() => {
				return <CopyMailingForm
					fetchCampaigns={this.fetchCampaigns}
					fetchCampaign={api.fetchCampaign}
					campaigns={this.props.campaigns}
					selectedCampaign={this.props.selectedCampaign}
					selectedMailingName={this.props.selectedMailing ? this.props.selectedMailing.name : ''}
					onSubmit={this.onCopySubmit}
					lastMessage={this.props.messages[COPY_MAILING_MESSAGE]}
				/>;
			}} />

			<Route path="/campaigns/:campaignId/mailings/:mailingId/edit" render={() => {
				const mailingForm = <MailingForm
					onSubmit={data => this.onUpdateMailingSubmit(this.props.selectedCampaign._id, this.props.selectedMailing._id, data)}
					data={this.props.selectedMailing}
					interestTypes={this.props.interestTypes}
				/>;

				return <UpdateOrCreateItem
					containerId="editMailing"
					headerText="Bewerk verzending"
					loaded={!!this.props.selectedMailing}
					form={mailingForm}
					lastMessage={this.props.messages[EDIT_MAILING_MESSAGE]}
					selectedCampaign={this.props.selectedCampaign}
				/>;
			}} />

			<Route path="/campaigns/:campaignId/mailings/new" render={() => {
				const mailingForm = <MailingForm
					newMailing={true}
					interestTypes={this.props.interestTypes}
					onSubmit={this.onNewMailingSubmit}
					hideExternal
				/>;

				return <UpdateOrCreateItem
					containerId="newMailing"
					headerText="Nieuwe mailing"
					loaded
					form={mailingForm}
					lastMessage={this.props.messages[NEW_MAILING_MESSAGE]}
					selectedCampaign={this.props.selectedCampaign}
				/>;
			}} />

			<Route path="/campaigns/:campaignId/mailings" render={() => {
				const campaignId = this.props.selectedCampaign._id;
				const baseURL = `/campaigns/${campaignId}/mailings`;
				const mailings = this.props.selectedCampaign.mailings.slice().reverse();

				return <ListMailings
					selectedCampaign={this.props.selectedCampaign}
					baseURL={baseURL}
					mailings={mailings}
					deleteMailing={this.deleteMailing}
					lastMessage={this.props.messages[LIST_MAILING_MESSAGE]}
					onLimitSubmit={(mailingId, limit) => this.onLimitSubmit(campaignId, mailingId, limit)}
					onPressureSubmit={mailingId => this.onPressureSubmit(campaignId, mailingId)}
					getPressure={mailingId => api.getMailingPressure(campaignId, mailingId)}
				/>;
			}} />
		</Switch>;

	render = () => this.props.renderWithLoader(this.props.selectedCampaign, this.renderItems);
}

const mapStateToProps = store => {
	const {selectedCampaign, selectedMailing, campaigns} = store.campaigns;
	const {interestTypes} = store.interestTypes;

	return {
		selectedCampaign,
		selectedMailing,
		campaigns,
		interestTypes,
	};
};

export default connect(mapStateToProps)(withLoader(withCampaigns(withMessages(withPrompts(MailingContainer)))));
