export const SET_INTEREST_TYPES = 'SET_INTEREST_TYPES';

export function setInterestTypes(types) {
	return {
		type: SET_INTEREST_TYPES,
		payload: {
			types,
		},
	};
}
