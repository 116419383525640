export const SET_CAMPAIGNS = 'SET_CAMPAIGNS';
export const SET_CAMPAIGN = 'SET_CAMPAIGN';
export const CLEAR_CAMPAIGN = 'CLEAR_CAMPAIGN';
export const SET_SELECTED_MAILING = 'SET_SELECTED_MAILING';
export const CLEAR_SELECTED_MAILING = 'CLEAR_SELECTED_MAILING';
export const SET_SELECTIONS = 'SET_SELECTIONS';
export const CLEAR_SELECTIONS = 'CLEAR_SELECTIONS';

export function setCampaigns(campaigns) {
	return {
		type: SET_CAMPAIGNS,
		payload: {
			campaigns,
		},
	};
}

export function setCampaign(campaign) {
	return {
		type: SET_CAMPAIGN,
		payload: {
			campaign,
		},
	};
}

export function clearCampaign() {
	return {
		type: CLEAR_CAMPAIGN,
	};
}

export function setSelectedMailing(mailing) {
	return {
		type: SET_SELECTED_MAILING,
		payload: {
			mailing,
		},
	};
}

export function clearSelectedMailing() {
	return {
		type: CLEAR_SELECTED_MAILING,
	};
}

export function setSelections(selections) {
	return {
		type: SET_SELECTIONS,
		payload: {
			selections,
		},
	};
}

export function clearSelections() {
	return {
		type: CLEAR_SELECTIONS,
	};
}
