export const SET_PARTNER_ID = 'SET_PARTNER_ID';
export const SET_DATASET_ID = 'SET_DATASET_ID';
export const SET_DATASET_HEADERS = 'SET_DATASET_HEADERS';
export const SET_GLOBAL_MAPPINGS = 'SET_GLOBAL_MAPPINGS';
export const SET_MAPPING_FOR_HEADER = 'SET_MAPPING_FOR_HEADER';
export const SET_DATASET_MAPPINGS = 'SET_DATASET_MAPPINGS';
export const SET_SELECTED_MAPPING = 'SET_SELECTED_MAPPING';
export const SET_SELECTED_MAPPING_HEADERS = 'SET_SELECTED_MAPPING_HEADERS';
export const SET_SELECTED_MAPPING_HEADER_VALUE = 'SET_SELECTED_MAPPING_HEADER_VALUE';
export const SET_GLOBAL_VALUE_MAPPINGS = 'SET_GLOBAL_VALUE_MAPPINGS';

export function setPartnerId(partnerId) {
	return {
		type: SET_PARTNER_ID,
		payload: {
			partnerId,
		},
	};
}

export function setDatasetId(datasetId) {
	return {
		type: SET_DATASET_ID,
		payload: {
			datasetId,
		},
	};
}

export function setDatasetHeaders(headers) {
	return {
		type: SET_DATASET_HEADERS,
		payload: {
			headers,
		},
	};
}

export function setGlobalMappings(globalMappings) {
	return {
		type: SET_GLOBAL_MAPPINGS,
		payload: {
			globalMappings,
		},
	};
}

export function updateMapping(mapping, payload) {
	return {
		type: SET_MAPPING_FOR_HEADER,
		payload: {
			id: mapping ? mapping.id : null,
			...payload,
		},
	};
}

export function setDatasetMappings(mappings, nextId = mappings.length) {
	return {
		type: SET_DATASET_MAPPINGS,
		payload: {
			mappings,
			nextId,
		},
	};
}

export function setSelectedMapping(mapping) {
	return {
		type: SET_SELECTED_MAPPING,
		payload: {
			mapping,
		},
	};
}

export function setSelectedMappingHeaders(headers) {
	return {
		type: SET_SELECTED_MAPPING_HEADERS,
		payload: {
			headers,
		},
	};
}

export function setSelectedMappingHeaderValue(mapping, header, value) {
	return {
		type: SET_SELECTED_MAPPING_HEADER_VALUE,
		payload: {
			mapping,
			header,
			value,
		},
	};
}

export function setGlobalValueMappings(mappings) {
	return {
		type: SET_GLOBAL_VALUE_MAPPINGS,
		payload: {
			mappings,
		},
	};
}

export function importValueMappings(mapping, mappings) {
	return {
		type: SET_SELECTED_MAPPING_HEADER_VALUE,
		payload: {
			mapping,
			mappings
		},
	};
}
