import {SET_APP_LOADED} from '../actions/app';

const initialState = {
	loaded: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_APP_LOADED:
			return Object.assign({}, state, { loaded: true });

		default:
			return state;
	}
};

export default reducer;
